import { Link } from "gatsby"
import React, { useState } from "react"
import Chevron from '../../chevron';
import '../../../styles/components/publication-card.css';

const PublicationCard = ({ title, authors, publisher, description, year, link }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    // swap state to the value it wasn't before
    setCollapsed((current) => { return !current });
  }
  return <div className='publication--card-wrapper'>
    <div className='publication--card'>
      <div className='info'>
        <Link to={link}>
          <h3 className='title'>{title}</h3>
        </Link>
        <p className='author'>{authors}</p>
        <p className='publisher'>{publisher} {year}</p>
        <p
          className='desc'
          style={collapsed ? { maxHeight: '155px' } : { maxHeight: '100vh' }}>
            {description}
        </p>
      </div>
      <div className={collapsed ? 'collapsed' : 'expanded'} onClick={toggleCollapsed}>
        <Chevron rotate={collapsed} color={'#333333'}/>
      </div>
    </div>
  </div>
}

PublicationCard.defaultProps = {
  title: `As yet untitled`,
  authors: `Authors unavailable`,
  publisher: `No Publisher`,
  description: `No description available`,
  year: ``,
  link: '#',

}

export default PublicationCard