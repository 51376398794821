import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Gallery from "../components/galleries/publication/publications-gallery"
import BackgroundImage from "../components/utilities/background-image"
import SEO from "../components/utilities/seo"

import "../styles/pages/publications.css"

const PublicationsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "artwork/Breakwater.jpeg" }) {
        ...galleryImage
      }
    }
  `);
  
  return <Layout>
      <SEO title="Publications" />
      <div className='site-background'>
        <BackgroundImage src={ data.background } />
        <div className='page-wrapper--limited'>
        <SEO title="Publications" />
          <h1 className='main-title'>Publications</h1>
          <div className='publications-container flex-container'>
            <Gallery/>
          </div>
          <Link to="/">Back to the homepage</Link>
        </div>
      </div>
    </Layout>
}

export default PublicationsPage;
