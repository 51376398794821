export const metaData = {
  a: {
    title: `There is Art Everywhere`,
    authors: `Book`,
    publisher: `Templar Books/Big Picture Press`,
    description: `Keith is an Art Historian. She also happens to be an Ostrich, and on a one-bird mission to teach people how to talk about Art…`,
    year: `forthcoming 2022`,
    link: ``,
  },
  b: {
    title: `Mathematics and the Craft of Thought in the Anglo-Dutch Renaissance`,
    authors: `Book`,
    publisher: `Routledge`,
    description: `Geometry had something of a mercurial nature in the sixteenth century. Inherited from antiquity in the form of Euclid’s Elements, it was many years before it moved from the scholastic sphere and its language and logic began to be used in an explicitly practical context. In 1570 Henry Billingsley translated Euclid’s Elements, into the English vernacular; in 1604 Jan Pieterszoon Dou followed suit and produced the first translation in the Dutch vernacular. These were both seminal moments in what is now known as the scientific revolution, but they were part of a broader shift towards the establishment of geometry as a practical and analytical tool. Mathematics and the Craft of Thought seeks to shed light on the remarkable culture shift that occurred around the turn of the seventeenth century, and the geometrical imagination which it gave rise to. Throughout, it will be seen that the visual language of early modern European geometry was constructed with borrowings and quotations from the contemporary visual culture. Practical geometry in this period was built out of craft metaphors. Far from being simply immaterial, the way in which this form of mathematics communicates, both verbally and visually, is designed to tantalize with material connotations.`,
    year: `forthcoming 2021`,
    link: ``,
  },
  c: {
    title: `Picturing Music in the English Renaissance, in British Art Studies`,
    authors: `Article`,
    publisher: ``,
    description: `Music in the early modern period was profoundly visual. This is not simply in terms of the spectacle of performance, in masques, court entertainments and the playhouses, but also because music became a ‘read’ thing for the first time. This article seeks to bridge the gap between the ways in which renaissance England is understood by art history, and the ways in which it is understood by musicology. In so doing, it aims to establish a dialogue between these methodologies. The English Renaissance was a period of great musical innovation, spawning the works of John Taverner, Thomas Tallis, William Byrd and Orlando Gibbons amongst others, despite the religious upheavals of the Reformation and Marian counterreformations. However in art history it is still largely seen as a cultural backwater. By focusing on the material remnants of musical culture such as the images that record musical occasions, and the reproductions of written music in painted and carved objects, this article argues that, on the contrary, music is a crucial source of visual interest in the English Renaissance.`,
    year: `forthcoming 2022`,
    link: ``,
  },
  d: {
    title: `The ‘English’ Cadence and Reading and Early Modern Musical Trope, in Early Music, 49: 2`,
    authors: `Article`,
    publisher: ``,
    description: `Twisting through a dissonantly flattened seventh now more commonly recognizable as the Jazz ‘blue’ seventh, the ‘English’ cadence is a distinctive feature of sixteenth century English choral music. Typically embedded within the texture of a piece in an inner part, this distinctive contrapuntal pattern works by pulling against its own regularity: it highlights its predictability, the regularity of the cadence, by spiraling, just briefly, into the unpredictable. Nevertheless, despite the fact that it is easily identified, since Thomas Morley dubbed it a thing ‘naught and stale’ the ‘English’ cadence has had a dubious reception, both in terms of its Englishness and its role within the choral textures of the sixteenth century. Much of this doubt derives from the status of the cadence as ‘ornament’ or ‘decoration’. As such the ‘English’ cadence is often viewed as superfluous to the tonality of the music as a whole. However, these interpretations do not take into account the contemporary thinking about ornament and decoration in sixteenth century England. This article seeks to demonstrate that by looking beyond the scant contemporary accounts of the ‘English’ cadence in musical theoretical treatises towards the visual culture of the period, it is possible to move towards a more nuanced understanding of the way the cadence creates meaning.`,
    year: `February 2021`,
    link: `https://doi.org/10.1093/em/caaa082`,
  },
  e: {
    title: `What We Mean When We Talk About Style: The ‘Redolent’ Eglantine Table (c. 1568)`,
    authors: `Article`,
    publisher: `Word & Image`,
    description: `Created in around 1568 to commemorate a trio of dynastic marriages between the Cavendishes and the Talbots, the Aeglentyne Table stands in the High Great Chamber at Hardwick Hall. Featuring an intricate three-paneled motif of musical instruments, gaming paraphernalia and intertwining flowers borrowed from various continental prints, it has long been held as a masterpiece of English furniture. However when approached through a traditional stylistic analysis, based upon the identification of tropes and features, the Eglantine Table runs the risk of falling under the category of ‘bad’ style: it is paratactic; it is epideictic. Such a judgment utterly misses the point of the Eglantine Table, and its visual strategies. Style has become a ubiquitous term for the analysis of the things of visual culture. It allows the historian of visual culture to categorize into a shorthand which belies the sheer variety of the objects of study. The work of Willibald Sauerländer amongst others has long since argued for deeper reflection on ‘style’, and a shift away from the ‘sheer abysmal vagueness’ of stylistic analysis. This article seeks to respond to Sauerländer’s call by providing a sensitive and illuminating analysis of the style[s] of the Eglantine Table.`,
    year: `September 2020`,
    link: `https://doi.org/10.1080/02666286.2019.1709345`,
  },
  f: {
    title: `The Vital Breath: Mathematical Visualizations in England and the Low Countries c. 1600, Ad Vivum: Visual Materials and the Vocabulary of Lifelikeness in Europe before 1800`,
    authors: `ed. Joanna Woodall, Thomas Balfe and Claus Zittel`,
    publisher: `Leiden: Brill Intersections`,
    description: `Euclid’s Elements of Geometry (1570), John Dee makes a cursory reference to ‘they which are not hable to atteine to this, without lively teaching’. It is a commonplace: successful didactics require dynamism. Dee’s observation, however, highlights a pairing which seldom sits well in the conceptual architecture of visualization which we, in the twenty first century, have inherited from the history of western art. The ‘lively’ and the highly abstract mathematical system (which during Dee’s lifetime was beginning to achieve a formalized and fixed state in northern Europe) are rarely associated. Nevertheless, bringing the two notions into dialogue can provide fascinating and surprising insight into how mathematics was understood, visually, stylistically, and in terms of lifelikeness. The essay will develop this argument in relation to the intertwined mathematical cultures which developed in early modern England and the Netherlands around the turn of the seventeenth century.`,
    year: `2019`,
    link: `https://doi.org/10.1163/9789004393998_009`,
  },
  g: {
    title: `Fantasia on a Harpsichord Case: The Allegorische Voorstelling van Amsterdam of Pieter Isaacsz’ in Renaissance Studies`,
    authors: `Article`,
    publisher: ``,
    description: `Lost for many years, the Amsterdam city harpsichord case (alias the Allegorische Voorstelling van Amsterdam) was ultimately identified in an auction in 1999 by its distinctive representation of the sky. The Allegorische Voorstelling is often taken to depict the successes of the Dutch East India Company, and thus seen predominantly as a colonial work. However, this reading suppresses the context of the case’s creation and the way in which it interacts with both the intellectual and musical culture of its time. It is a musical instrument, not simply a panel painting: it has sonic resonance. In this period, the senses were important cognitive tools. As a result, the sonic context of the Allegorische Voorstelling would have been far more resonant, and far more perceptible, to its original viewers than it is to twenty‐first century viewers. This article seeks to demonstrate the value of examining the Allegorische Voorstelling with full appreciation of what music and hearing meant for cognition reveals a subtler, undercurrent to its territorial statement than first appears.`,
    year: `June 2019`,
    link: `https://doi.org/10.1111/rest.12518`,
  },
  h: {
    title: `Beautiful Surfaces: Style and Substances in Florentius Schuyl’s Illustrations for Descartes’ Treatise on Man’ in Nuncius`,
    authors: `Article`,
    publisher: ``,
    description: `The assumption that the Cartesian bête-machine is the invention of René Descartes (1596–1650) is rarely contested. Close examination of Descartes’ texts proves that this is a concept founded not on the basis of his own writings, but a subsequent critical interpretation, which developed and began to dominate his work after his death. Descartes’ Treatise on Man, published posthumously in two rival editions, Florentius Schuyl’s Latin translation De Homine (1662), and Claude Clerselier’s Traité de l’ homme, has proved particularly problematic. The surviving manuscript copies of the Treatise on Man left no illustrations, leaving both editors the daunting task of producing a set of images to accompany and clarify the fragmented text. In this intriguing case, the images can be seen to have spoken louder than the text which they illustrated. This paper assesses Schuyl’s choice to represent Descartes’ Man in a highly stylized manner, without superimposing Clerselier’s intentions onto De Homine.`,
    year: `June 2016`,
    link: `https://doi.org/10.1163/18253911-03102001`,
  },
}

export default metaData