import React from "react"

import PublicationCard from "./publication-card"
import metaData from "../../metadata/publications-metadata"

const Gallery = () => {

  if (metaData) {
    const cards = Object.keys(metaData).map(function(value, index) {
      return <PublicationCard
        key={ index }
        title={ metaData[value].title }
        authors={ metaData[value].authors }
        publisher={ metaData[value].publisher }
        description={ metaData[value].description }
        year={ metaData[value].year }
        link={ metaData[value].link }
      ></PublicationCard>
    });
    console.log(cards)

    return cards;
  }
  return <div><div>No publications to show</div></div>
}

export default Gallery;