import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import standardImage from "./image-queries"

const BackgroundImage = ({src}) => {
  if (src) return (
    <div className='site-background-wrapper'>
      <Img fluid={src.childImageSharp.fluid}/>
    </div>
  )
  return <div></div>
}

export default BackgroundImage
