import React from 'react';

interface ChevronProps {
  color?: string;
  rotate?: boolean;
}

function Chevron(props: ChevronProps) {
  const {  color='white', rotate=false }: ChevronProps = props;
  return (
    <svg x="0px" y="0px" width="444.819px" height="444.819px" viewBox="0 0 444.819 444.819">
      <g>
        <path fill={color} d="M352.025,196.712L165.884,10.848C159.029,3.615,150.469,0,140.187,0c-10.282,0-18.842,3.619-25.697,10.848L92.792,32.264   c-7.044,7.043-10.566,15.604-10.566,25.692c0,9.897,3.521,18.56,10.566,25.981l138.753,138.473L92.786,361.168   c-7.042,7.043-10.564,15.604-10.564,25.693c0,9.896,3.521,18.562,10.564,25.98l21.7,21.413   c7.043,7.043,15.612,10.564,25.697,10.564c10.089,0,18.656-3.521,25.697-10.564l186.145-185.864   c7.046-7.423,10.571-16.084,10.571-25.981C362.597,212.321,359.071,203.755,352.025,196.712z" />
      </g>
      <style>{`
        svg {
          flex: 1;
          height: 100%;
          width: 100%;
          transition: ease 0.3s;
          transform: ${rotate ? 'rotate(180deg);' : ''}
        }
      `}
      </style>
    </svg>
    );
}

export default Chevron;
